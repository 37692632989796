<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div
    id="observer-root"
    class="observation"
    :style="{ backgroundColor: backgroundColor }"
  >
    <Head>
      <Title>{{ pageData.pageTitle }}</Title>
      <Meta
        name="description"
        hid="description"
        :content="pageData.pageDescription"
      />
      <Meta name="og:image" hid="og:image" :content="OG_IMAGE" />
    </Head>
    <BlocksNewGlobalHero v-bind="hero[0]" />
    <BlocksNewContentLayout :content="content" />
  </div>
</template>
<script setup>
import { DIASPORA_START_PAGE_QUERY } from '@/utils/graphql-queries'
import { OG_IMAGE } from '@/utils/resources'
const datoHelperClient = useDatoHelper()
const currentLocale = useLocale() || 'en'

const { setLocale } = useI18n()

definePageMeta({
  stopDefaultSwitch: true,
  key: 'home',
  middleware: ['diaspora-redirect']
})

const route = useRoute()

const { $bus, $mRouter } = useNuxtApp()

const backgroundColor = ref('black')

const content = ref([])

const hero = ref({})

const query = `query MyQuery {
      diasporaStartPage(locale: ${currentLocale}, filter: {country: {eq: "global"}}) {
          ${DIASPORA_START_PAGE_QUERY}
        }
      }`

const response = await datoHelperClient.request(query, 'HomePage' + currentLocale)

const pageData = response.diasporaStartPage

content.value = pageData.content
hero.value = pageData.hero

const handleLocaleSwitch = async (val) => {
  let country
  if (val.destinationCountry) {
    country = await useCountries().getCountry(val.destinationCountry)
  }
  const countrySlug = country ? country.alternativeSlugs[val.destinationLocale] : null
  setLocale(val.destinationLocale).then(() => {
    if (countrySlug) {
      window.location.href = $mRouter.getLocalRoute({ name: 'country', params: { country: countrySlug } }).href
    } else {
      window.location.href = $mRouter.getLocalRoute({ name: 'index' }).href
    }
  })
}

$bus.onDynamicRouteChangeLanguage(route.meta.key, handleLocaleSwitch)

</script>
<style scoped>
.observation {
  transition: background-color 0.5s ease;
  -webkit-transition: background-color 0.5es ease;
}
</style>
